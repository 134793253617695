var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row bg-white" }, [
    _c("div", { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12" }, [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6" },
          [
            _c("apexchart", {
              ref: "riskChart",
              attrs: {
                height: "400",
                width: _vm.riskChart.chartWidth,
                options: _vm.riskChart.chartOptions,
                series: _vm.riskChart.series,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6" },
          [
            _c("apexchart", {
              ref: "riskStandardChart",
              attrs: {
                height: "400",
                width: _vm.riskStandardChart.chartWidth,
                options: _vm.riskStandardChart.chartOptions,
                series: _vm.riskStandardChart.series,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6" },
          [
            _c("apexchart", {
              ref: "processImprChart",
              attrs: {
                height: "260",
                type: "bar",
                width: _vm.processImprChart.chartWidth,
                options: _vm.processImprChart.chartOptions,
                series: _vm.processImprChart.series,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6" },
          [
            _c("apexchart", {
              ref: "vendorImprChart",
              attrs: {
                height: "260",
                type: "bar",
                width: _vm.vendorImprChart.chartWidth,
                options: _vm.vendorImprChart.chartOptions,
                series: _vm.vendorImprChart.series,
              },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }